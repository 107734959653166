export enum Platforms {
    YouTubeTV = 1,
    NBALeaguePass = 2,
    SlingTV = 3,
    Xfinity = 4,
    FuboTV = 5,
    Optimum = 6,
    Spectrum = 7,
    DIRECTV = 8,
    Cox = 9,
}

export let platformsToNames: { [key: number]: string } = {
    [Platforms.YouTubeTV]: 'YouTube TV',
};
platformsToNames[Platforms.YouTubeTV] = 'YouTube TV';
platformsToNames[Platforms.NBALeaguePass] = 'NBA League Pass';
platformsToNames[Platforms.SlingTV] = 'Sling TV';
platformsToNames[Platforms.Xfinity] = 'Xfinity';
platformsToNames[Platforms.FuboTV] = 'FuboTV';
platformsToNames[Platforms.Optimum] = 'Optimum';
platformsToNames[Platforms.Spectrum] = 'Spectrum';
platformsToNames[Platforms.DIRECTV] = 'DIRECTV';
platformsToNames[Platforms.Cox] = 'Cox';

type NamePlatformMap = Record<string, Platforms>;
export let namesToPlatforms: NamePlatformMap = {};
namesToPlatforms[platformsToNames[Platforms.YouTubeTV]] = Platforms.YouTubeTV;
namesToPlatforms[platformsToNames[Platforms.NBALeaguePass]] = Platforms.NBALeaguePass;
namesToPlatforms[platformsToNames[Platforms.SlingTV]] = Platforms.SlingTV;
namesToPlatforms[platformsToNames[Platforms.Xfinity]] = Platforms.Xfinity;
namesToPlatforms[platformsToNames[Platforms.FuboTV]] = Platforms.FuboTV;
namesToPlatforms[platformsToNames[Platforms.Optimum]] = Platforms.Optimum;
namesToPlatforms[platformsToNames[Platforms.Spectrum]] = Platforms.Spectrum;
namesToPlatforms[platformsToNames[Platforms.DIRECTV]] = Platforms.DIRECTV;
namesToPlatforms[platformsToNames[Platforms.Cox]] = Platforms.Cox;

export enum SubscriptionTypes {
    None = 1,
    YouTubeTVBase = 2,
    FuboTVStarter = 3,
    FuboTVPro = 4,
    FuboTVElite = 5,
    FuboTVLatino = 6,
    SlingTVOrange = 7,
    SlingTVBlue = 8,
    SlingTVOrangeAndBlue = 9,
    DIRECTVSelect = 10,
    DIRECTVChoice = 11,
    DIRECTVEntertainment = 12,
    DIRECTVXtra = 13,
    DIRECTVUltimate = 14,
    DIRECTVPremier = 15,
    DIRECTVStreamEntertainment = 16,
    DIRECTVStreamChoice = 17,
    DIRECTVStreamUltimate = 18,
    DIRECTVStreamPremier = 19,
    NBALeaguePass = 20,
    NBALeaguePassPremium = 21,
    NBALeagueTeamPass = 22,
    NBALeaguePassIntl = 23,
    NBALeaguePassPremiumIntl = 24,
    NBALeagueTeamPassIntl = 25,
    CoxStarter = 26,
    CoxPreferred = 27,
    CoxUltimate = 28,
    XfinityDigitalStarter = 29,
    XfinityDigitalPreferred = 30,
    XfinityDigitalPremier = 31,
    SpectrumSelect = 32,
    SpectrumSilver = 33,
    SpectrumGold = 34,
    OptimumBroadcastBasic = 35,
    OptimumCore = 36,
    OptimumValue = 37,
    OptimumSelect = 38,
    OptimumPremier = 39,
    OptimumEconomy = 40,
    Failure = 41,
    XfinityX1Platform = 42,
}

export let subTypesToNames: { [key: number]: string } = {
    [SubscriptionTypes.None]: 'None',
    [SubscriptionTypes.YouTubeTVBase]: 'YouTubeTVBase',
    [SubscriptionTypes.FuboTVStarter]: 'FuboTVStarter',
    [SubscriptionTypes.FuboTVPro]: 'FuboTVPro',
    [SubscriptionTypes.FuboTVElite]: 'FuboTVElite',
    [SubscriptionTypes.FuboTVLatino]: 'FuboTVLatino',
    [SubscriptionTypes.SlingTVOrange]: 'SlingTVOrange',
    [SubscriptionTypes.SlingTVBlue]: 'SlingTVBlue',
    [SubscriptionTypes.SlingTVOrangeAndBlue]: 'SlingTVOrangeAndBlue',
    [SubscriptionTypes.DIRECTVSelect]: 'DIRECTVSelect',
    [SubscriptionTypes.DIRECTVChoice]: 'DIRECTVChoice',
    [SubscriptionTypes.DIRECTVEntertainment]: 'DIRECTVEntertainment',
    [SubscriptionTypes.DIRECTVXtra]: 'DIRECTVXtra',
    [SubscriptionTypes.DIRECTVUltimate]: 'DIRECTVUltimate',
    [SubscriptionTypes.DIRECTVPremier]: 'DIRECTVPremier',
    [SubscriptionTypes.DIRECTVStreamEntertainment]: 'DIRECTVStreamEntertainment',
    [SubscriptionTypes.DIRECTVStreamChoice]: 'DIRECTVStreamChoice',
    [SubscriptionTypes.DIRECTVStreamUltimate]: 'DIRECTVStreamUltimate',
    [SubscriptionTypes.DIRECTVStreamPremier]: 'DIRECTVStreamPremier',
    [SubscriptionTypes.NBALeaguePass]: 'NBALeaguePass',
    [SubscriptionTypes.NBALeaguePassPremium]: 'NBALeaguePassPremium',
    [SubscriptionTypes.NBALeagueTeamPass]: 'NBALeagueTeamPass',
    [SubscriptionTypes.NBALeaguePassIntl]: 'NBALeaguePassIntl',
    [SubscriptionTypes.NBALeaguePassPremiumIntl]: 'NBALeaguePassPremiumIntl',
    [SubscriptionTypes.NBALeagueTeamPassIntl]: 'NBALeagueTeamPassIntl',
    [SubscriptionTypes.CoxStarter]: 'CoxStarter',
    [SubscriptionTypes.CoxPreferred]: 'CoxPreferred',
    [SubscriptionTypes.CoxUltimate]: 'CoxUltimate',
    [SubscriptionTypes.XfinityDigitalStarter]: 'XfinityDigitalStarter',
    [SubscriptionTypes.XfinityDigitalPreferred]: 'XfinityDigitalPreferred',
    [SubscriptionTypes.XfinityDigitalPremier]: 'XfinityDigitalPremier',
    [SubscriptionTypes.SpectrumSelect]: 'SpectrumSelect',
    [SubscriptionTypes.SpectrumSilver]: 'SpectrumSilver',
    [SubscriptionTypes.SpectrumGold]: 'SpectrumGold',
    [SubscriptionTypes.OptimumBroadcastBasic]: 'OptimumBroadcastBasic',
    [SubscriptionTypes.OptimumCore]: 'OptimumCore',
    [SubscriptionTypes.OptimumValue]: 'OptimumValue',
    [SubscriptionTypes.OptimumSelect]: 'OptimumSelect',
    [SubscriptionTypes.OptimumPremier]: 'OptimumPremier',
    [SubscriptionTypes.OptimumEconomy]: 'OptimumEconomy',
};

export enum AddonTypes {
    YoutubeTVLeaguePass = 0,
    YoutubeTVSportsPlus = 1,
    FuboTVSportsPlus = 2,
    FuboTVExtra = 3,
    FuboTVInternationalSportsPlus = 4,
    FuboTVAdventurePlus = 5,
    FuboTVNBALeaguePass = 6,
    FuboTVSportsLite = 7,
    FuboTVLatinoPlus = 8,
    FuboTVRAIItalia = 9,
    FuboTVTV5MONDE = 10,
    FuboTVPortuguesePlus = 11,
    SlingTVTotalTVDeal = 12,
    SlingTVSportsExtras = 13,
    SlingTVWorldSportsMini = 14,
    SlingTVNBALeaguePass = 15,
    SlingTVNBATeamPass = 16,
    SlingTVSlingDeportes = 17,
    DIRECTVSportsPack = 18,
    DIRECTVNFLSundayTicket = 19,
    DIRECTVMLBExtraInnings = 20,
    DIRECTVNBALeaguePass = 21,
    DIRECTVFOXSoccerPlus = 22,
    DIRECTVMLSDirectKick = 23,
    DIRECTVNHLCenterIce = 24,
    DIRECTVDeportes = 25,
    DIRECTVStreamNBALeaguePass = 26,
    DIRECTVStreamDeportes = 27,
    CoxSportsAndInfoPack = 28,
    CoxSportsPack2 = 29,
    XfinitySportsAndEntertainment = 30,
    XfinityLeaguePass = 31,
    XfinityCenterIce = 32,
    XfinitySportsAndNews = 33,
}

export let addonsToNames: { [key: number]: string } = {
    [AddonTypes.YoutubeTVLeaguePass]: 'YoutubeTVLeaguePass',
    [AddonTypes.YoutubeTVSportsPlus]: 'YoutubeTVSportsPlus',
    [AddonTypes.FuboTVSportsPlus]: 'FuboTVSportsPlus',
    [AddonTypes.FuboTVExtra]: 'FuboTVExtra',
    [AddonTypes.FuboTVInternationalSportsPlus]: 'FuboTVInternationalSportsPlus',
    [AddonTypes.FuboTVAdventurePlus]: 'FuboTVAdventurePlus',
    [AddonTypes.FuboTVNBALeaguePass]: 'FuboTVNBALeaguePass',
    [AddonTypes.FuboTVSportsLite]: 'FuboTVSportsLite',
    [AddonTypes.FuboTVLatinoPlus]: 'FuboTVLatinoPlus',
    [AddonTypes.FuboTVRAIItalia]: 'FuboTVRAIItalia',
    [AddonTypes.FuboTVTV5MONDE]: 'FuboTVTV5MONDE',
    [AddonTypes.FuboTVPortuguesePlus]: 'FuboTVPortuguesePlus',
    [AddonTypes.SlingTVTotalTVDeal]: 'SlingTVTotalTVDeal',
    [AddonTypes.SlingTVSportsExtras]: 'SlingTVSportsExtras',
    [AddonTypes.SlingTVWorldSportsMini]: 'SlingTVWorldSportsMini',
    [AddonTypes.SlingTVNBALeaguePass]: 'SlingTVNBALeaguePass',
    [AddonTypes.SlingTVNBATeamPass]: 'SlingTVNBATeamPass',
    [AddonTypes.SlingTVSlingDeportes]: 'SlingTVSlingDeportes',
    [AddonTypes.DIRECTVSportsPack]: 'DIRECTVSportsPack',
    [AddonTypes.DIRECTVNFLSundayTicket]: 'DIRECTVNFLSundayTicket',
    [AddonTypes.DIRECTVMLBExtraInnings]: 'DIRECTVMLBExtraInnings',
    [AddonTypes.DIRECTVNBALeaguePass]: 'DIRECTVNBALeaguePass',
    [AddonTypes.DIRECTVFOXSoccerPlus]: 'DIRECTVFOXSoccerPlus',
    [AddonTypes.DIRECTVMLSDirectKick]: 'DIRECTVMLSDirectKick',
    [AddonTypes.DIRECTVNHLCenterIce]: 'DIRECTVNHLCenterIce',
    [AddonTypes.DIRECTVDeportes]: 'DIRECTVDeportes',
    [AddonTypes.DIRECTVStreamNBALeaguePass]: 'DIRECTVStreamNBALeaguePass',
    [AddonTypes.DIRECTVStreamDeportes]: 'DIRECTVStreamDeportes',
    [AddonTypes.CoxSportsAndInfoPack]: 'CoxSportsAndInfoPack',
    [AddonTypes.CoxSportsPack2]: 'CoxSportsPack2',
    [AddonTypes.XfinitySportsAndEntertainment]: 'XfinitySportsAndEntertainment',
    [AddonTypes.XfinityLeaguePass]: 'XfinityLeaguePass',
    [AddonTypes.XfinityCenterIce]: 'XfinityCenterIce',
};

export const vpnRequiredPlatforms: Platforms[] = [Platforms.NBALeaguePass];
